<template>
  <div>
    <div class="modal fade" id="infoPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header row">
        <div class="col-md-11">
          <h5 class="modal-title" id="exampleModalLabel">{{ $t('youMustEnterClientNameAndMobileNumberTile') }}</h5>
        </div>
        <div class="col-md-1">

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        {{ $t('youMustEnterClientNameAndMobileNumberBody') }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">{{ $t('ok')}}</button>
      </div>
    </div>
  </div>
</div>
    <div class="modal fade bd-example-modal-lg p-20" data-backdrop="static" data-keyboard="false" a-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div class="row" style="width:100%">
          <div class="col-md-10 ">
            <h2 class="c-grey-900 mB-20" style="display: inline-block">
            {{ $t("orderDetails") +'('+' '+totalPrice+$t('rial')+' | '+' '+totalQuantity+$t('item')+')'}}
          </h2>
          </div>
        </div>
        
      </div>
      <div class="modal-body" style="height: 60vh;overflow-y: auto;">
      <table
            id="dataTable"
            class="table table-bordered dataTable table-hover"
            role="grid"
            aria-describedby="dataTable_info"
            style="width: 100%"
            width="100%"
            cellspacing="0"
          >
            <thead>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Position: activate to sort column ascending"
                >
                  {{ $t("items") }}
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Office: activate to sort column ascending"
                >
                  {{ $t("quantity") }}
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Office: activate to sort column ascending"
                >
                  {{ $t("washType") }}
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Age: activate to sort column ascending"
                >
                  {{ $t("orderType") }}
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Start date: activate to sort column ascending"
                >
                  {{ $t("price") }}
                </th>
            </thead>
            <tbody v-show="!loading" v-for="item in orderItems" :key="item.id">
              <tr role="row" class="odd">
                <td>
                  {{getTranslation(item.ar_name , item.en_name) }}
                </td>
                <td style="direction: ltr;">
                 {{ item.quantity }}
                </td>
                <td style="direction: ltr;">
                  {{ item.washType == 1 ? $t('prewash') : item.washType == 2 ? $t('press') : $t('dryClean')}}
                </td>
                <td>
                  {{item.orderType == 0 ? $t('normal') : $t('urgent')}}
                </td>
                <td>
                  {{item.price}}
                </td>
               
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button v-if="!orderLoading" type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('cancel') }}</button>
        <button v-if="!orderLoading" type="button" class="btn btn-primary" @click="generatePDF()">{{ $t('print') }}</button>
        <button v-if="!orderLoading" type="button" class="btn btn-success" @click="addOrder">{{ $t('confirm') }}</button>
        <loading v-show="orderLoading" />

      </div>
    </div>
  </div>
</div>
<div class="row">
    <div class="col-md-6">
      <div class="bgc-white bd bdrs-3 p-20 mB-20">
        <div class="row">
          <loading v-show="loading" />
          <div v-if="!loading" class="col-md-12">
            <input type="name" class="form-control" v-model="name" :placeholder="$t('search')" style="font-size: 25px;" />
          </div>
        </div>
      </div>
      <div v-if="!loading" class="bgc-white bd bdrs-3 p-20 mT-40">
        <div class="row">
          <div class="col-md-3 mT-10" v-for="item in getClothesByName()" :key="item.id">
            <div class="item" @click="addOrderItem(item)">
              <img width="50" :src="'https://lms.nubian-code.com/images/clothes/'+item.image" style="margin: auto;display: block;" />
              <h4 class="c-grey-900 mB-20" style="display: inline-block"> {{ getTranslation(item.ar_name , item.en_name) }} </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading" class="col-md-6">
      <div class="bgc-white bd bdrs-3 p-20 mB-20">
        <div class="row mB-40">
          <div class="col-md-5">
            <a href="javascript:" @click="deleteOrder">
              <span class="badge rounded-pill bgc-red-50 c-red-700">
                <i class="ti-trash" style="font-size: 20px;"></i>
              </span>
            </a>
          </div>
          <div class="col-md-2">
            <h4 class="text-success">{{ totalPrice+'\n'+$t("rial") }}</h4>
          </div>
          <div class="col-md-2">
            <h4>{{ totalQuantity+'\n'+$t("item") }}</h4>
          </div>
          <div class="col-md-3">
            <button class="btn btn-la btn-success" data-toggle="modal" :data-target="clientName == '' ? '#infoPopup' : '.bd-example-modal-lg'">
              <i class="ti-shopping-cart" style="color: #fff;font-size: 30px;"></i>
              <h5>{{ $t('checkout') }}</h5>
            </button>
          </div>
        </div>
        <form @submit="checkForm">
        <div class="form-row mT-20">
          <div class="form-group col-md-6">
            <label for="inputPassword4">{{ $t("clientMobileNumber") }}</label
            ><input dir="ltr" @input="handleInput" :title="$t('onlyNumber')" pattern="\d{8}" maxlength="8" type="text" @blur="getClientInfoByMobileNumber" @keydown="saveClientInfo($event,1);" :readonly="nameInfoLoading" class="form-control" @keydown.enter="getClientInfoByMobileNumber" id="inputPassword4" v-model="clientMobileNumber" required/>
            <div  v-if="nameInfoLoading" class="loading-indicator"></div>
          </div>
          <div class="form-group col-md-6">
            <label for="inputEmail4">{{ $t("clientName") }}</label
            ><input type="name" @keydown="saveClientInfo(0,this)" class="form-control" id="inputEmail4" :readonly="nameReadOnly" v-model="clientName" required />
          </div>
        </div>
        </form>
        <div v-for=" (item, index) in orderItems" :key="index">
          <div class="mT-20" style="border-style: solid;width:80%;border-width: 1px;border-bottom: 0;padding: 5px;">
            <div v-if="item.prices.find(obj => obj.wash_type_id == 1 && obj.order_type_id == (item.orderType+1))" class="form-check form-check-inline">
              <input class="form-check-input" @change="calculateTotalPrice()" type="radio" :id="'1'+index" :name="'grounp'+index" v-model="item.washType" value="1">
              <label class="form-check-label" :for="'1'+index">{{ $t('prewash') }}</label>
            </div>
            <div v-if="item.prices.find(obj => obj.wash_type_id == 2 && obj.order_type_id == (item.orderType+1))" class="form-check form-check-inline">
              <input class="form-check-input" @change="calculateTotalPrice()" type="radio" :id="'2'+index" :name="'grounp'+index" v-model="item.washType" value="2">
              <label class="form-check-label" :for="'2'+index">{{ $t('press') }}</label>
            </div>
            <div v-if="item.prices.find(obj => obj.wash_type_id == 3 && obj.order_type_id == (item.orderType+1))" class="form-check form-check-inline">
              <input class="form-check-input" @change="calculateTotalPrice()" type="radio" :id="'3'+index" :name="'grounp'+index" v-model="item.washType" value="3">
              <label class="form-check-label" :for="'3'+index">{{ $t('dryClean') }}</label>
            </div>
            <div style="width: 10%;display: inline-block;"></div>
            <a href="javascript:" @click="removeOrderItem(item)">
              <span class="badge rounded-pill bgc-red-50 c-red-700">
                <i class="ti-trash" style="font-size: 20px;"></i>
              </span>
            </a>
          </div>
          <div class="order-item row">
            <div class="col-md-2" style="text-align: center;">
              <img width="30" :src="'https://lms.nubian-code.com/images/clothes/'+item.image" style="display: block;margin: auto;" />
              <h6 class="c-grey-900">{{ getTranslation(item.ar_name,item.en_name) }}</h6>
            </div>
            <div class="col-md-4">
              <div class="num-block skin-2">
                <div class="num-in">
                  <span class="minus dis" @click="addQuantity(item , -1)"></span>
                  <input type="text" @keydown.enter="$event.target.blur"  @change="calculateTotalPrice(); calculateTotalQuantity();" class="in-num" v-model="item.quantity">
                  <span class="plus" @click="addQuantity(item , 1)"></span>
                </div>
              </div>
            </div>
            <div class="col-md-3" style="vertical-align: middle;text-align: center; padding-top: 12px;">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" @change="calculateTotalPrice()" id="index" v-model="item.orderType" value="1">
                <label class="form-check-label" for="index">{{ $t('urgent') }}</label>
              </div>
            </div>
            <div class="col-md-3" style="padding-top: 10px;">
              <span style="border-right: 1px solid #ddd; margin: 0 10px"></span>
              <h5 class="text-success" style="display: inline-block;">{{item.price+' '+ $t('rial') }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
  </div>
  
</template>
<script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import JsBarcode from 'jsbarcode';

  export default {
    data() {
      return {
        loading: true,
        orderLoading:false,
        clothes: [],
        orderItems: [],
        totalPrice: 0,
        totalQuantity: 0,
        clientMobileNumber:"",
        clientName:"",
        name: "",
        nameReadOnly: true,
        nameInfoLoading: false
      };
    },
    created() {
      this.getClothes();
      var orderItems = localStorage.getItem('orderItems');
      if(orderItems != null) {
        this.orderItems = JSON.parse(orderItems);
        this.clientMobileNumber = localStorage.getItem('clientMobileNumber');
        this.clientName = localStorage.getItem('clientName');
        this.calculateTotalPrice();
        this.calculateTotalQuantity();
      }
    },
    methods: {
      handleInput() {
      // Use regex to allow only numbers
      if (!/^\d*$/.test(this.numberValue)) {
        this.error = true;
      } else {
        this.error = false;
      }
    },
    validateKey(event) {
      // Prevent typing if the key is not a number
      const isNumberOrAllowedKey = /^\d*$/.test(event.key) || [8, 37, 39, 46, 36, 35, 17, 91, 18, 67, 88, 86, 65].includes(event.keyCode);    
      if (!isNumberOrAllowedKey) {
        event.preventDefault();
      }
      else {
        this.clientName = "";
      }
    },
      deleteOrder() {
        this.orderItems = [];
        this.calculateTotalPrice();
        this.calculateTotalQuantity();

      },
      saveClientInfo(event , readonly) {
        if(readonly){
          this.nameReadOnly=true;
        }
        if(event != null) this.validateKey(event);
        localStorage.setItem('clientName' , this.clientName == null ? "" : this.clientName);
        localStorage.setItem('clientMobileNumber',this.clientMobileNumber == null ? "" : this.clientMobileNumber);

      },
      getClothesByName() {
        console.log('name ' + this.name);
        const startsWithSArray = this.clothes.filter(element => element.ar_name.toLowerCase().startsWith(this.name.toLowerCase()) | element.en_name.toLowerCase().startsWith(this.name.toLowerCase()));
        return startsWithSArray
      },
      removeOrderItem(item) {
        const indexToRemove = this.orderItems.findIndex(obj => obj.id === item.id);
        if (indexToRemove !== -1) {
          this.orderItems.splice(indexToRemove, 1);
        }
        this.calculateTotalPrice();
        this.calculateTotalQuantity();
      },
      addOrderItem(item) {
        item = Object.assign({}, item);
        var washType = item.prices.find(obj => obj.order_type_id == 1);
        item.washType = washType.wash_type_id;
        item.quantity = 1;
        item.price = washType.price;
        item.orderType = 0;
        this.orderItems.unshift(item);
        this.calculateTotalPrice();
        this.calculateTotalQuantity();
      },
      getTranslation(ar, en) {
        return localStorage.getItem('lang') == 'ar' ? ar : en;
      },
      checkForm: function(e) {
        e.preventDefault();
        this.addOrder();
      },
      addQuantity: function(item, quantity) {
        item.quantity = parseInt(item.quantity);
        if (item.quantity + quantity == 0) {
          return;
        }
        item.quantity += quantity;
        var washType = item.prices.find(obj => obj.wash_type_id == item.washType & obj.order_type_id == (item.orderType + 1));
        item.price = washType.price * item.quantity;
        this.calculateTotalPrice();
        this.calculateTotalQuantity();
      },
      calculateTotalPrice() {
        this.orderItems.forEach((item, index) => {
          item.price = item.quantity * item.prices.find(obj => obj.wash_type_id == item.washType & obj.order_type_id == (item.orderType + 1)).price;
        });

        localStorage.setItem('orderItems',JSON.stringify(this.orderItems));
        this.totalPrice = this.orderItems.reduce((sum, currentItem) => sum + currentItem.price, 0);
      },
      calculateTotalQuantity() {
        this.totalQuantity = this.orderItems.reduce((sum, currentItem) => sum +parseInt( currentItem.quantity), 0);
      },
      getClothes: function() {
        this.loading = true;
        this.$http.get("/backend/orders/getClothes", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          params: {},
        }).then(function(data) {
            this.loading = false;
            switch (data.status) {
              case 200:
                this.clothes = data.body;
                break;
            }
          },
          (err) => {
            this.loading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
                break;
            }
          });
      },
      getClientInfoByMobileNumber: function() {
        if(this.clientMobileNumber.length != 8) return;

        this.nameInfoLoading = true;
        this.$http.get("/backend/clients/getClientInfoByMobileNumber", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          params: {
            mobile_number: '+974'+this.clientMobileNumber
          },
        }).then(function(data) {
          this.nameInfoLoading = false;
            this.loading = false;
            switch (data.status) {
              case 200:
                this.clientName =data.body.name;
                break;
            }
          },
          (err) => {
            this.loading = false;
            this.nameInfoLoading = false;
            switch (err.status) {
              case 409 :
                  this.clientName = '';
                  this.nameReadOnly = false;
                  break;
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
                break;
            }
          });
      },
      addOrder: function() {
        this.orderLoading =true;
        let headers = {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        };
        const itemsId = [];
        for (let i = 0; i < this.orderItems.length; i++) {
          const orderItem = this.orderItems[i];
          const newObject = {
            cloth_id: orderItem.id,
            wash_type_id: orderItem.washType,
            order_type_id: orderItem.orderType+1,
            quantity: orderItem.quantity
          };
          itemsId.push(newObject);
        }
        this.$http.post("/backend/orders/add", {
          items: itemsId,
          name:this.clientName,
          mobile_number: this.clientMobileNumber
        }, {
          headers
        }).then(function(data) {
          this.orderLoading=false;
            this.loading = false;
            $('.bd-example-modal-lg').modal('hide');
            switch (data.status) {
              case 201:
              this.totalQuantity = 0;
            this.totalPrice=0;
                this.clientMobileNumber = "";
                this.clientName = "";
                this.orderItems = [];
                localStorage.removeItem('orderItems');
                localStorage.removeItem('clientName');
                localStorage.removeItem('clientMobileNumber');
                this.$emit("closePanel", {});
                this.success(this.$t("success"));
                this.reload();
                break;
            }
          },
          (err) => {
            this.loading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
            }
          });
      },
      error: function(message) {
        this.orderLoading = false;
        this.$toast.error(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      warning: function(message) {
        this.$toast.warning(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      success: function(message) {
        this.$toast.success(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },

      generatePDF() {
      // Create a new PDF document
      const pdf = new jsPDF();

      // Client information
      const clientName = 'John Doe';
      const clientMobile = '123-456-7890';
      const orderDate = new Date().toLocaleDateString('en-US');
      pdf.text(`Client Name: ${this.clientName}`, 20, 20);
      pdf.text(`Mobile Number: ${this.clientMobile}`, 20, 30);
      pdf.text(`Order Date: ${orderDate}`, 20, 40);
      pdf.text(`Order ID: ${orderDate}`, 20, 50);

      // Add a table of order list
      const orderList = [
        // Add more order details as needed
      ];


      this.orderItems.forEach(item => {
        orderList.push({
          item: item.en_name,
          quantity: item.quantity,
          washType: item.washType == 1 ? 'Prewash' : item.washType == 2 ? 'Press' : 'Dry Clean',
          orderType: item.orderType == 0 ? 'Normal' : 'Urgent'
        });
      });

      const headers = ['Item', 'Quantity', 'Wash Type', 'Order Type'];
      const rows = orderList.map(order => Object.values(order));

      pdf.autoTable({
        head: [headers],
        body: rows,
        startY: 60,
      });

      // Calculate total quantity
      const totalQuantity = orderList.reduce((total, order) => total + order.quantity, 0);
      pdf.text(`Total Quantity: ${totalQuantity}`, 20, pdf.autoTable.previous.finalY + 10);

      // Generate barcode and add to the PDF
      const barcodeCanvas = document.createElement('canvas');
      JsBarcode(barcodeCanvas, '123456789', {
        format: 'CODE128',
        displayValue: false,
      });

      const barcodeDataURL = barcodeCanvas.toDataURL('image/png');
      pdf.addImage(barcodeDataURL, 'PNG', 20, pdf.autoTable.previous.finalY + 20, 40, 20);

      // Save or open the PDF
      pdf.save('order.pdf');
    }
    },
  };
</script>
<style>
  .item {
    border-style: solid;
    text-align: center;
    border-width: 1px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .item:hover {
    background-color: #1976d2;
    cursor: pointer;
  }

  .order-item {
    border-style: solid;
    border-radius: 10px;
    border-width: 1px;
    padding: 10px 0px;
  }

  .num-block {
    float: left;
    width: 100%;
    padding: 0px 30px;
  }

  .skin-2 .num-in {
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    height: 40px;
    width: 110px;
    float: left;
  }

  .skin-2 .num-in span {
    width: 40%;
    display: block;
    height: 40px;
    float: left;
    position: relative;
  }

  .skin-2 .num-in span:before,
  .skin-2 .num-in span:after {
    content: '';
    position: absolute;
    background-color: #667780;
    height: 2px;
    width: 10px;
    top: 50%;
    left: 50%;
    margin-top: -1px;
    margin-left: -5px;
  }

  .skin-2 .num-in span.plus:after {
    transform: rotate(90deg);
  }

  .skin-2 .num-in input {
    float: left;
    width: 20%;
    height: 40px;
    border: none;
    text-align: center;
  }
.loading-indicator {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  margin-top:-25px;
  border-top: 2px solid #3498db;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>